import React, { useEffect, useState } from "react";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { FaChevronRight } from "react-icons/fa";
import {
  FullBackground,
  Icon,
  Layout,
  PageContainer,
  SEO,
  ThreadPopup,
  WhyCloudLabsNav,
} from "components";
import { Link as GatsbyLink } from "gatsby";
import { device, routes } from "utils";

const WhyCloudLabEfficiencyPage = ({ location }) => {
  const title = "Cloud Labs Outperform CROs, Incubators, & Internal ";
  const description =
    "Reach your experimental objectives faster and at a lower cost";
  const [show, setShowContent] = useState(false);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  // Should only enter when location changes, so toggling
  // will still be possible with or without #capacity in URL.
  // By appending '#capacity' in the URL, we can directly
  // navigate to the thread popup window.
  useEffect(() => {
    if (location && location.hash.includes("capacity")) {
      setModal(true);
    }
  }, [location]);

  setTimeout(() => {
    setShowContent(true);
  }, 200);

  return (
    <FullBackground gradient>
      <WhyCloudLabsNav section={location && location.pathname} />
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <FadeAnimation show={show}>
          <PageContainer tight withNav>
            <NavRow>
              <NavLink>
                <DirectionLink to={routes("WCLFlexibilityTraditional")}>
                  <LinkText>NEXT: FLEXIBILITY</LinkText>
                  <RightArrowIcon />
                </DirectionLink>
              </NavLink>
            </NavRow>
            <Heading>
              <Header>
                Select your account type to see how cloud lab efficiency
                compares with traditional models.
              </Header>
            </Heading>
            <StyledRow>
              <StyledCol xs={12} sm={3}>
                <StyledTitle>STARTUP</StyledTitle>
                <StyledDescription>
                  A complete research solution for early to mid-stage companies.
                  Seamlessly upgrade to Enterprise as you scale.
                </StyledDescription>
                <CapacityTypes>
                  <CapacityButton to={routes("WCLEfficiencyStartupBaseline")}>
                    Baseline Capacity
                  </CapacityButton>
                  <CapacityButton to={routes("WCLEfficiencyStartupHigh")}>
                    High Capacity
                  </CapacityButton>
                  <CapacityButton to={routes("WCLEfficiencyStartupUltra")}>
                    Ultra Capacity
                  </CapacityButton>
                </CapacityTypes>
                <CapacityInfo onClick={toggle}>
                  <StyledIcon name="InfoIcon" />
                  <CapacityText>Learn about capacity</CapacityText>
                </CapacityInfo>
                <StyledGraphic name="StartupIcon" />
              </StyledCol>
              <StyledCol xs={12} sm={3}>
                <StyledTitle>ENTERPRISE</StyledTitle>
                <StyledDescription>
                  Enterprise-scale R&amp;D solution with GxP support. For
                  companies with 1000+ employees.
                </StyledDescription>
                <CapacityTypes>
                  <CapacityButton
                    to={routes("WCLEfficiencyEnterpriseBaseline")}
                  >
                    Baseline Capacity
                  </CapacityButton>
                  <CapacityButton to={routes("WCLEfficiencyEnterpriseHigh")}>
                    High Capacity
                  </CapacityButton>
                  <CapacityButton to={routes("WCLEfficiencyEnterpriseUltra")}>
                    Ultra Capacity
                  </CapacityButton>
                </CapacityTypes>
                <CapacityInfo onClick={toggle}>
                  <StyledIcon name="InfoIcon" />
                  <CapacityText>Learn about capacity</CapacityText>
                </CapacityInfo>
                <StyledGraphic name="EnterpriseIcon" />
              </StyledCol>
              <StyledCol xs={12} sm={3}>
                <StyledTitle>ACADEMIA</StyledTitle>
                <StyledDescription>
                  The full power of ECL, available for academia and other
                  non-profit institutions.
                </StyledDescription>
                <CapacityTypes>
                  <CapacityButton to={routes("WCLEfficiencyAcademia")}>
                    Standard Capacity
                  </CapacityButton>
                </CapacityTypes>
                <StyledGraphic name="AcademiaIcon" />
              </StyledCol>
            </StyledRow>
            <NavRow>
              <NavLink>
                <DirectionLink to={routes("WCLFlexibilityTraditional")}>
                  <LinkText>NEXT: FLEXIBILITY</LinkText>
                  <RightArrowIcon />
                </DirectionLink>
              </NavLink>
            </NavRow>
            <ThreadPopup toggle={toggle} modal={modal} />
          </PageContainer>
        </FadeAnimation>
      </Layout>
    </FullBackground>
  );
};

export default WhyCloudLabEfficiencyPage;

// Styles

const FadeAnimation = styled.div`
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 2s ease;
`;

const NavRow = styled(Row)`
  margin-bottom: 3rem;
  margin-top: 1rem;
  flex-wrap: nowrap;
  justify-content: flex-end;

  @media ${device.md} {
    margin-bottom: 1.3rem;
  }
`;

const NavLink = styled.div`
  max-width: 16rem;
`;

const DirectionLink = styled(GatsbyLink)`
  color: ${({ theme }) => theme.greenLink};
  display: flex;
  cursor: default;
  align-items: center;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1.7rem;
  &:hover {
    color: ${({ theme }) => theme.greenLink};
    text-decoration: none;
  }
`;

const LinkText = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const RightArrowIcon = styled(FaChevronRight)`
  height: 10px;
`;

const Heading = styled.div`
  letter-spacing: 0.05rem;
  margin-top: 2.5rem;
  margin-bottom: 0;
  text-align: center;

  @media ${device.md} {
    margin-bottom: 3.5rem;
  }
`;

const Header = styled.p`
  color: #e5e8ea;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.6rem;
  margin-top: 0;

  @media ${device.md} {
    margin-top: 1.5rem;
  }
`;

const StyledRow = styled(Row)`
  justify-content: center;
  margin: 0 2rem 2rem 2rem;

  @media ${device.md} {
    margin: 2.6rem -0.7rem 6rem -0.7rem;
  }
`;

const StyledCol = styled(Col)`
  background: ${({ theme }) => theme.black};
  height: 25rem;
  margin: 3rem 0;
  padding: 2rem;
  position: relative;

  @media ${device.md} {
    text-align: center;
    height: 48.8rem;
    margin: 0rem 1.75rem;
    padding: 1.3rem 3rem 0 3rem;
    // transition: all 0.5s ease;
    // &:hover {
    //   transform: scale(1.1);
    // }
  }
`;

const StyledTitle = styled.h3`
  color: #ffffff;
  line-height: 3rem;
  font-size: 1.6rem;
  margin-bottom: 0;

  @media ${device.md} {
    margin-bottom: 2rem;
  }
`;

const StyledDescription = styled.p`
  color: #b1bac2;
  font-size: 1.4rem;
  line-height: 1.7rem;
  margin-bottom: 2rem;

  @media ${device.md} {
    height: 9rem;
    margin-bottom: 2.3rem;
  }
`;

const CapacityTypes = styled.div`
  display: flex;
  justify-content: center;

  @media ${device.md} {
    height: 15rem;
    flex-direction: column;
  }
`;

const CapacityButton = styled(GatsbyLink)`
  flex: 1;
  display: block;
  cursor: pointer;
  border: 1px solid #949ea7;
  color: #b9c2cb;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.6rem;
  padding: 0.8rem 0;
  &:hover {
    color: #ffffff;
    border-color: #ffffff;
  }
  &:not(:first-child) {
    margin-left: 2rem;
  }

  @media ${device.md} {
    flex: 0 0 auto;
    &:not(:first-child) {
      margin-left: 0;
    }
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const CapacityInfo = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
  cursor: pointer;
  &:hover {
    > div {
      color: #ffffff;
    }
  }

  @media ${device.md} {
    justify-content: center;
  }
`;

const CapacityText = styled.div`
  color: #77838e;
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 1.6rem;
`;

const StyledIcon = styled(Icon)`
  height: 1.8rem;
  width: 1.8rem;
  margin-top: 0;
  margin-right: 0.5rem;
`;

const StyledGraphic = styled(Icon)`
  position: absolute;
  top: 16rem;
  right: 0;
  height: 12rem;
  width: 12rem;

  @media ${device.md} {
    top: 36rem;
    left: 3rem;
    height: 18rem;
    width: 18rem;
  }
`;
